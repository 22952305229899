import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import openVideoModalSlice from '../features/common/openVideoModalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import leadsSlice from '../features/leads/leadSlice'
import waitModalSlice from '../features/common/waitModalSlice'
import onBordingModalSlice from '../features/common/onBordingModalSlice'
const combinedReducer = {
  header : headerSlice,
  rightDrawer : rightDrawerSlice,
  modal : modalSlice,
  lead : leadsSlice,
  openmodal: openVideoModalSlice,
  submodal: waitModalSlice,
  onboardingmodal : onBordingModalSlice

}

export default configureStore({
    reducer: combinedReducer
})