import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import { useAuth } from "./app/auth";
import initializeApp from "./app/init";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { get, ref as rtdbRef } from "firebase/database";
import { H } from "highlight.run";
import { Buffer } from "buffer";
//import posthog from "posthog-js";
import mixpanel from "mixpanel-browser";
import { MODAL_BODY_TYPES } from "./utils/globalConstantUtil";
import { useDispatch } from "react-redux";
import { openModal } from "./features/common/modalSlice";

// import ReactPixel from 'react-facebook-pixel';

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));
const NewDashboard = lazy(() => import("./pages/protected/NewDashboard"));

// Initializing different libraries
initializeApp();

// posthog.init('phc_hmlfqnxDPO7f58NJjY7tibWDh1QvLbv5bTsOhckfHdK', {
//   api_host: 'https://us.i.posthog.com',
// })
mixpanel.init("1aec2cf79499b33b34fe106249d83999", {
  track_pageview: "url-with-path",
  persistence: "cookie",
  debug: true,
});
// let urlParams = new URLSearchParams(window.location.search);
// let distinctId = urlParams.get('distinctId');
// if(distinctId) {
//   console.log("distinctId", distinctId);
//   mixpanel.identify(distinctId);
// }

function App() {
  // Check for login and initialize axios
  const auth = useAuth();
  const dispatch = useDispatch();
  //eslint-disable-next-line
  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (auth?.user?.["screenName"] || auth?.user?.["displayName"]) {
      listenToSubscriptionData();
    }

    // eslint-disable-next-line
  }, [auth?.user]);

  const listenToSubscriptionData = async () => {
    //listen to the subscription data from realtime user database
    if (auth && auth.user) {
      const subscriptionRef = rtdbRef(
        auth?.rtdb,
        "subscription/" + auth?.user.localId
      );
      get(subscriptionRef).then((snapshot) => {
        // onValue(subscriptionRef, (snapshot) => {
        const data = snapshot.val();
        printDataSize(data);
        setSubscriptionData(data);

        if (!data) {
          console.log("NO Subscription Data: Creating...");

          const getSubscriptionInfo = httpsCallable(
            auth?.functions,
            "getSubscriptionInfo"
          );
          getSubscriptionInfo();
        } else {
          if (!data?.faceData) {
            dispatch(
              openModal({
                title: "",
                size: "",
                defaultType: "onBoarding",
                bodyType: MODAL_BODY_TYPES.LEAD_ADD_NEW,
              })
            );
          }
          const date = new Date(data["createdAt"]);

          //eslint-disable-next-line
          const createdAt =
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
          H.identify(
            auth?.user?.["screenName"] || auth?.user?.["displayName"],
            {
              firebaseId: auth?.user?.["localId"],
              status: data["status"],
              createdAt: createdAt,
            }
          );
          // const { status, renewDateAt, credits } = data;
          const status = data?.status;
          const renewDateAt = data?.renewDateAt;
          const credits = data?.credits;

          console.log(status);
          console.log(renewDateAt);
          console.log(credits);

          if (status === "trial" && credits === undefined) {
            updateUserCredits(status, 0, null);
          } else if (
            renewDateAt === undefined ||
            renewDateAt <= new Date().getTime()
          ) {
            updateUserCredits(status, credits, renewDateAt);
          } else if (!data.purchaseCompleteFunnelHit && status !== "trial") {
            console.log("User Subscribed to Plan: Updating...");
            const updateSubscriptionInfo = httpsCallable(
              auth?.functions,
              "updateSubscriptionInfo"
            );
            updateSubscriptionInfo({ purchaseCompleteFunnelHit: true });

            try {
              window.fbq("track", "Subscribe", {
                predicted_ltv: 100,
                currency: "USD",
              });
              //posthog.capture("subscribed", { plan: status, currency: "USD" });
              mixpanel.track("subscribed", { plan: status, currency: "USD" });
              console.log("META PIXEL: SUBSCRIBED");
            } catch (error) {
              console.log("META PIXEL DID NOT FIRE");
            }
          }

          if (!data.migrationToCreditsCompleted) {
            if (status === "Lite Plan" || status === "Hobbyist Plan") {
              const updateSubscriptionInfo = httpsCallable(
                auth?.functions,
                "updateSubscriptionInfo"
              );
              updateSubscriptionInfo({
                credits: status === "Lite Plan" ? 50 + credits : 500 + credits,
                migrationToCreditsCompleted: true,
              });
            }
          }
        }
      });
    }
  };

  const updateUserCredits = (status, currentCredits, renewDateAt) => {
    console.log("execute updateUserCredits");
    const updateSubscriptionInfo = httpsCallable(
      auth?.functions,
      "updateSubscriptionInfo"
    );
    if (status === "trial") {
      console.log("Trial Plan: Assigning 50 initial credits...");
      updateSubscriptionInfo({
        credits: 10,
      });
      return; // No need for renewal logic for trial users
    }
    const today = new Date();
    let renewalDate = renewDateAt ? new Date(renewDateAt) : today;
    renewalDate.setDate(renewalDate.getDate() + 30);

    const planCredits = {
      "Lite Plan": 50 + (currentCredits || 0),
      "Hobbyist Plan": 500 + (currentCredits || 0),
      "Creator Plan": 1000 + (currentCredits || 0),
    };
    console.log(`${status} Plan: Updating Credits and Renewal Date...`);
    updateSubscriptionInfo({
      renewDateAt: renewalDate.getTime(),
      credits: planCredits[status],
    });
  };

  const printDataSize = (data) => {
    let dataJSON = JSON.stringify(data);
    let dataJSONSize = Buffer.byteLength(dataJSON, "utf8"); //convert to kb
    dataJSONSize = dataJSONSize / 1024;
    console.log("<strong> Data Size App.js: " + dataJSONSize + " kb</strong>");
  };

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/dashboardV3" element={<NewDashboard />} />
          {/* <Route path="/auto-generate" element={} /> */}
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route
            path="*"
            element={
              <Navigate to={auth?.user ? "/app/welcome" : "/login"} replace />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
