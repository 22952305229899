import { createSlice } from '@reduxjs/toolkit'

export const openVideomodalSlice = createSlice({
    name: 'openmodal',
    initialState: {
        isOpen : false,
        openProcessVideo : "",
        size : "",
    },
    reducers: {

        opentiktokVideoModal: (state, action) => {
            const {openProcessVideo,size} = action.payload
            state.isOpen = true
            state.openProcessVideo = openProcessVideo
            state.size = size

        },

        closetiktokVidoeModal: (state, action) => {
            state.isOpen = false
            state.openProcessVideo = ""
            state.size = ""
        },

    }
})

export const { opentiktokVideoModal, closetiktokVidoeModal } = openVideomodalSlice.actions

export default openVideomodalSlice.reducer