import { createSlice } from '@reduxjs/toolkit'

export const onBoardingModalSlice = createSlice({
    name: 'onboardingmodal',
    initialState: {
        isOpen : false,
        icon : "",
        size : "",
        openOnBoard: ""
    },
    reducers: {
        openModal: (state, action) => {
            const {size,icon,openOnBoard} = action.payload
            state.isOpen = true
            state.size = size || 'md'
            state.icon = icon
            state.openOnBoard = openOnBoard
          
        },

        closeModal: (state, action) => {
            state.isOpen = false
            state.icon = ""
            state.openOnBoard = ""

        },
    }
})

export const { openModal, closeModal } = onBoardingModalSlice.actions
export default onBoardingModalSlice.reducer